import * as c from "./constant";
import { Map, fromJS, List } from "immutable";

const initState = Map({
  isAuthenticated: false,
  profile: Map({}),
  countries: List([]),
  categories: List([]),
  clients: List([]),
  members: List([]),
  location: List([]),
  jobs: List([]),
  meta: Map({}),
  job_details: Map({}),
  links: Map({}),
  client_logos: Map({}),
  news: List([]),
  advisories: List([]),
  interviews: List([]),
  check_user: List([]),
  applicant_sources: List([]),
  client_testimonials: List([]),
  faq: List([]),
  news_details: List([]),
  recover_code: Map({
    valid: false,
  }),
  remove_account_code: Map({
    valid: false,
  }),
  login_data: Map({
    email: "",
    password2: "",
  }),
  testimonial: List([]),
  form_data: Map({
    lengthUnit: "FEET",
    weightUnit: "KG",
    photo: "",
    jobcat1: "",
    jobcat2: "",
    jobcat3: "",
    firstname: "",
    middlename: "",
    lastname: "",
    suffix: "",
    address: "",
    location: "",
    email: "",
    password: "",
    password_confirmation: "",
    month: "",
    day: "",
    year: "",
    gender: "",
    civilstatus: "",
    height: "",
    heightinches: "",
    heightUnit: "CM",
    heightInc: "",
    nationality: "",
    religion: "",
    mobile: "",
    mobile2: "",
    yearsofexp: "",
    resume: "",
    resumeuploaded: "",
    source: "",
    availability: "",
    defect: "",
    birthdate: "",
    search: "",
    evaluation_form: "",
    is_nurse: "",
    self_assessment_form: "",
    password2: "",
  }),
});

export default (state = initState, action) => {
  switch (action.type) {
    case c.AUTHENTICATE:
      return state.set("isAuthenticated", action.isSuccess);
    case c.PROFILE:
      return state.set("profile", fromJS(action.data));
    case "RESET_AUTH":
      return initState;
    case c.GOT_COUNTRY:
      return state.set("countries", fromJS(action.data));
    case c.GOT_CATEGORY:
      return state.set("categories", fromJS(action.data));
    case c.GOT_FAQ:
      return state.set("faq", fromJS(action.data));
    case c.GOT_TESTIMONIAL:
      return state.set("testimonial", fromJS(action.data));
    case c.GOT_CLIENT:
      return state.set("clients", fromJS(action.data));
    case c.GOT_LOCATION:
      return state.set("location", fromJS(action.data));
    case c.GOT_JOBS:
      return state
        .set("jobs", fromJS(action.data))
        .set("meta", fromJS(action.meta));
    case c.GOT_JOB_DETAILS:
      return state.set("job_details", fromJS(action.data));
    case c.CLEAR_LIST:
      return state.set("jobs", initState.get("jobs"));
    case c.GOT_NEWS:
      return state.set("news", fromJS(action.data));
    case c.GOT_ADVISORIES:
      return state.set("advisories", fromJS(action.data));
    case c.GOT_INTERVIEW:
      return state.set("interviews", fromJS(action.data));
    case c.GOT_NEWS_DETAILS:
      return state.set("news_details", fromJS(action.data));
    case c.GOT_LINKS:
      return state.set("links", fromJS(action.data));
    case c.GOT_CLIENT_LOGOS:
      return state.set("client_logos", fromJS(action.data));
    case c.GOT_RECOVER:
      return state.set("recover_code", fromJS(action.data));
    case c.GOT_REMOVE_ACCOUNT_CONFIRM:
      return state.set("remove_account_code", fromJS(action.data));
    case c.GOT_CLIENT_TESTIMONIAL:
      return state.set("client_testimonials", fromJS(action.data));
    case c.GOT_CHECK_USER:
      return state.set("check_user", fromJS(action.data));
    case c.GOT_APPLICANT_SOURCE:
      return state.set("applicant_sources", fromJS(action.data));
    case c.SET_FORM_DATA:
      return state.update("form_data", (form_data) =>
        form_data.merge(fromJS(action.data))
      );
    case c.SET_LOGIN_DATA:
      return state.update("login_data", (login_data) =>
        login_data.merge(fromJS(action.data))
      );
    case c.CLEAR_FORM_DATA:
      return state.set("form_data", initState.get("form_data"));
    default:
      return state;
  }
};
